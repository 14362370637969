<template>
<v-menu offset-y :open-on-hover="$vuetify.breakpoint.mdAndUp">
  <template v-slot:activator="{ on, attrs }">
    <span
      v-if="!$vuetify.breakpoint.mdAndUp"
      v-bind="attrs"
      @click.stop.prevent="on.click && on.click($event)"
      :style="menuText ? 'text-decoration: underline; text-decoration-style: dotted;' : ''">{{ text }}</span>
    <span
      v-else
      v-bind="attrs"
      v-on="on"
      :style="menuText ? 'text-decoration: underline; text-decoration-style: dotted;' : ''">{{ text }}</span>
  </template>
  <v-list v-if="menuText" style="max-width: 250px">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>{{ menuText.title }}</v-list-item-title>
        <v-list-item-subtitle v-if="menuText.subtitle">{{ menuText.subtitle }}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item>
      <v-list-item-content>
        {{ menuText.content }}
      </v-list-item-content>
    </v-list-item>
  </v-list>
</v-menu>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    menuText () {
      if (this.text === 'Collaborators') {
        return {
          title: 'Collaborators',
          content: 'Collaborators can edit the context and record new observation entries, but they can only edit or delete entries they reported. Collaborators cannot archive, delete, or submit a SchoolDog Walk for review, and they cannot manage access.',
        }
      }
      if (this.text === 'Leader') {
        return {
          title: 'Leader',
          content: 'The Leader has full access to edit and view a SchoolDog Walk. The Leader can make a Collaborator the Leader instead, but they will lose the ability to manage access.',
        }
      }
      if (this.text === 'School Leaderboard') {
        return {
          title: 'School Leaderboard',
          content: 'The School Leaderboard displays the schools who have the most observation entries from school-led SchoolDog Walks in the current cycle.',
        }
      }
      if (this.text === 'Top Walkers') {
        return {
          title: 'Top Walkers',
          content: 'A "Top Walker" is a user who has shown a high level of dedication to proactively identifying praises and concerns. Anyone can become a Top Walker by logging more observation entries.',
        
        }
      }
      if (this.text.includes('Neighborhood Watch')) {
        return {
          title: 'Neighborhood Watch Notifications',
          content: 'The "Neighborhood Watch" notification is a great way to remind other users in your system to proactively identify praises and concerns. It is recommended to send this notification when you are completing a SchoolDog Walk with 10+ observation entries or when no SchoolDog Walks have been completed recently.',
        }
      }
      return null
    },
  },
}
</script>

<style scoped>
</style>

<template>
<v-menu v-if="user" offset-y :open-on-hover="$vuetify.breakpoint.mdAndUp">
  <template v-slot:activator="{ on, attrs }">
    <a v-bind="attrs" v-on="on">
      <span style="text-decoration: underline; text-decoration-style: dotted;">{{user.firstName}} {{user.lastName}}<span v-if="user.id === currentUser.id"> (You)</span></span>
    </a>
  </template>

  <v-list>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>{{user.firstName}} {{user.lastName}}</v-list-item-title>
        <v-list-item-subtitle>{{user.title || user.friendlyRole}}</v-list-item-subtitle>
        <!-- Add more user details here -->
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item v-if="!user.isDistrictUser() && user.roleSchoolIds.length">
      <v-list-item-content>
        <v-list-item-title>
          School{{ user.roleSchoolIds.length > 1 ? 's' : '' }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ schoolDescription}}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          Email
        </v-list-item-title>
        <v-list-item-subtitle>
          {{user.email}}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <!-- You can add more list items for additional info -->
  </v-list>
</v-menu>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
export default {
  props: {
    userId: String,
  },
  computed: {
    ...mapGetters('app', [
      'getSchoolById',
    ]),
    ...mapState({
      currentUser: (state) => state.app.user,
      usersById: state => state.app.usersById,
    }),
    user () {
      return this.usersById[this.userId]
    },
    schoolDescription () {
      if (!this.user.isDistrictUser()) {
        const schoolIds = this.user.roleSchoolIds;
        let str = '';
        for (let i = 0; i < schoolIds.length; i++) {
          const school = this.getSchoolById(schoolIds[i]);
          if (school) {
            str += school.name;
            if (i < schoolIds.length - 1) {
              str += ', ';
            }
          }
        }
        return str;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

import FirestoreModel from './FirestoreModel';

class Settings extends FirestoreModel {
    static collectionName = 'settings';
    static allowedFields = [
      'categories',
      'district',
      'items',
      'observationCategories',
      'observationItems',
      'riskGroups',
      'schools',
      'currentCycle',
    ]

    constructor(id, data) {
      super(id, data);
    }
}

export default Settings;

<template>
  <div v-if="walk && user && !isLoading" style="display: flex; width: 100%;" :style="!$vuetify.breakpoint.mdAndUp ? 'padding-bottom: 84px' : ''">
    <div style="flex: 1;">
      <v-toolbar
        color="#ffffff"
        style="left: 0; border-bottom: 1px solid #1b7476;"
        width="100%">
        <walk-progress-bar v-model="currentTab" :walk="walk" />
        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          @click="isSchoolResourcesDialogVisible = true"
          class="ml-2"
          color="#C58C99"
          icon
        >
            <v-icon>mdi-help-box-multiple-outline</v-icon>
          </v-btn>
        <v-btn @click="isSettingsDialogVisible = true" color="grey" icon>
          <v-icon>mdi-cog</v-icon>
        </v-btn>
        <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndUp" @click.stop="isObservationsDrawerVisible = !isObservationsDrawerVisible" ></v-app-bar-nav-icon>
      </v-toolbar>
      <div class="px-3 py-2"  :style="$vuetify.breakpoint.mdAndUp ? 'overflow-y: auto; height: calc(100vh - 64px - 64px)' : ''">
        <div v-if="-1 === currentTab">
          <walk-header :walk="walk"></walk-header>
          <walk-history-card
            v-if="walk.submissionHistory && walk.submissionHistory.length"
            :walk="walk"
            class="mt-1 mb-2"></walk-history-card>

          <div class="row justify-center">
            <v-btn
              @click="currentTab = 1"
              color="primary"
              large
              outlined
            >
              Continue
            </v-btn>
          </div>
        </div>
        <div v-if="0 === currentTab">
          <div class="row justify-center pa-2 pt-0">
            <div
              v-for="observationCategory in observationCategories"
              :key="observationCategory.id"
              class="col-6"
              style="padding: 8px"
            >
              <v-card
                @click="openCreateObservationDialogForCategory(observationCategory.id)"
                class="ma-0 pa-0"
                style="height: 100%; width: 100%; display: flex; flex-direction: column;"
              >
                <div class="text-subtitle-2 text-center pa-1">{{ observationCategory.label }}</div>
                <v-spacer></v-spacer>
                <div class="row justify-center mb-1" style="align-items: end;">
                  <v-img
                    :src="require(`@/assets/images/${getImagePathForCategory(observationCategory)}.png`)"
                    color="transparent"
                    contain
                    style="border: 2px solid gray; border-radius: 1000px; max-height: 80px !important; max-width: 80px !important;"></v-img>
                </div>
              </v-card>
            </div>
            <div
              class="col-6"
              style="padding: 8px"
            >
              <v-card
                @click="openCreateObservationDialogForCategory(null)"
                class="ma-0 pa-0"
                style="height: 100%; width: 100%; display: flex; flex-direction: column;"
              >
                <div class="text-subtitle-2 text-center pa-1">Master List</div>
                <div class="row justify-center mb-1" style="align-items: end;">
                  <v-img
                    :src="require(`@/assets/images/a_to_z.png`)"
                    color="transparent"
                    contain
                    style="border: 2px solid gray; border-radius: 1000px; max-height: 80px !important; max-width: 80px !important;"></v-img>
                </div>
                <v-spacer></v-spacer>
                <div class="row justify-center mb-1" style="align-items: end;">
                </div>
              </v-card>
            </div>
          </div>
          <div class="row justify-center">
            <v-btn
              @click="currentTab = 1"
              color="primary"
              large
              outlined
            >
              Continue
            </v-btn>
          </div>
        </div>

        <div v-if="1 === currentTab">
          <v-card class="mb-2">
            <v-card-text>
              <div class="text-subtitle-1">
                Have any additional data to record?
              </div>
              <div class="d-flex">
                <div v-if="!walkObservations.length" class="mr-2">
                  <v-icon color="warning" large>mdi-alert</v-icon>
                </div>
                <div>
                  You are about to complete a SchoolDog Walk containing {{ numberOfConcerns }} concern{{numberOfConcerns === 1 ? '' : 's'}}, {{ numberOfPraises}} praise{{numberOfPraises === 1 ? '' : 's'}}, and {{ numberOfOther }} other entries. <span v-if="!walkObservations.length">It is recommended to record at least one entry before completing.</span><span v-else>If you have more entries to record, please do so before completing.</span> <a @click="currentTab = 0" href="javascript:void(0)">Go back to record more entries</a>.
                  <div>
                  <div v-if="!walkObservations.length" class="mt-2">
                    If you no longer want to save the data in this SchoolDog Walk, you can <a @click="isDeleteWalkDialogVisible = true" href="javascript:void(0)">permanently delete it</a>.
                  </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
          <div class="text-h5 text--secondary">
            Additional context <span style="font-size: 14px">(<a @click="scrollToSubmit()" href="javascript:void(0)">Skip</a>)</span>
          </div>
          <v-card class="mt-1 mb-2" style="max-width: 100%">
            <v-card-text>
              <div class="text-subtitle-1">
                How was the weather?
              </div>
              <div
                :class="{
                  'row flex-nowrap px-3': $vuetify.breakpoint.mdAndUp,
                }"
                class="mt-1"
              >
                <v-select
                  v-model="walk.context.weatherPrecipitation"
                  @input="
                    saveWalk('precip')
                  "
                  :items="['Light Rain', 'Heavy Rain', 'Cloudy', 'Sunny', 'Ice/Snow', 'Other']"
                  dense
                  hide-details
                  label="Precipitation"
                  outlined
                  :class="{
                    'mr-1': $vuetify.breakpoint.mdAndUp,
                  }"
                  :menu-props="{ offsetY: true }"
                ></v-select>
                <v-select
                  v-model="walk.context.weatherTemperature"
                  @input="
                    saveWalk('temp')
                  "
                  :items="['Below 33 Degrees Fahrenheit', '33-39 Degrees Fahrenheit', '40-49 Degrees Fahrenheit', '50-59 Degrees Fahrenheit', '60-69 Degrees Fahrenheit', '70-79 Degrees Fahrenheit', '80+ Degrees Fahrenheit']"
                  dense
                  hide-details
                  label="Temperature"
                  outlined
                  :class="{
                    'ml-1': $vuetify.breakpoint.mdAndUp,
                    'mt-1': !$vuetify.breakpoint.mdAndUp,
                  }"
                  :menu-props="{ offsetY: true }"
                ></v-select>
              </div>
              <v-text-field
                v-model="walk.context.weatherComment"
                @blur="saveWalk('wc')"
                class="mt-1"
                hide-details
                label="Comment"
                outlined
              ></v-text-field>
            </v-card-text>
          </v-card>
          <v-card class="mt-1 mb-2">
            <v-card-text>
              <div class="text-subtitle-1">
                Observation checklist
              </div>
              <div v-for="(category, index) in observationCategories" :key="index">
                <v-checkbox
                  v-model="walk.assessedCategories"
                  :value="category.id"
                  @change="saveWalk('assessed')"
                  dense
                  hide-details>
                  <template v-slot:label>
                    <div>
                      I toured the facility to look for <b>{{ ` ${category.label} ` }}</b> praises and concerns.
                    </div>
                  </template>
                </v-checkbox>
              </div>
            </v-card-text>
          </v-card>
          <v-card class="mt-1 mb-2">
            <v-card-text>
              <div class="text-subtitle-1">
                Any final notes?
              </div>
              <v-textarea
                v-model="walk.context.additionalNotes"
                @blur="saveWalk('add')"
                :rows="3"
                auto-grow
                class="mt-1"
                hide-details
                label="Additional notes"
                outlined
                dense
              ></v-textarea>
            </v-card-text>
          </v-card>
          <div v-if="isOffline" class="text-center error--text text--darken-2 mb-2">
            You are currently offline, meaning you cannot Review and Complete. Your data for this SchoolDog Walk will be saved to your local device until you reconnect to the internet. Please try to complete after connecting to the internet.
          </div>
          <div v-if="walk.leaderUserId !== user.id" class="text-center error--text text--darken-2 mb-2">
            Only the Leader for a SchoolDog Walk can Review and Complete.<br>The Leader for this SchoolDog Walk is <user-link :userId="walk.leaderUserId" />.
          </div>
          <div class="row justify-center">
            <v-btn
              @click="isReviewingSubmissionDialogVisible = true"
              :disabled="isSubmitting || isOffline || walk.leaderUserId !== user.id"
              ref="submitBtn"
              color="primary"
              x-large
            >
              Review and Complete
            </v-btn>
          </div>
          <div class="row justify-center">
            <v-btn
              @click="currentTab = 0"
              color="grey darken-1"
              large
              text
            >
              Back
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <v-navigation-drawer
      v-if="$vuetify.breakpoint.mdAndUp"
      v-model="isObservationsDrawerVisible"
      class="elevation-1"
      floating
      permanent
      right
      width="360px"
      :style="!(isObservationsDrawerVisible && $vuetify.breakpoint.mdAndUp) ? 'width: 0' : ''"
    >
      <div>
        <div>
          <div style="display: flex; justify-content: center; align-items: center;">
            <div style="flex: 1">
              <v-card-title>
                Observation entries
              </v-card-title>
              <v-card-subtitle>
                {{ numberOfConcerns }} concern{{numberOfConcerns === 1 ? '' : 's'}}, {{ numberOfPraises}} praise{{numberOfPraises === 1 ? '' : 's'}}, {{ numberOfOther }} other
              </v-card-subtitle>
            </div>
            <div class="pa-1">
              <v-btn
                @click="openCreateObservationDialogForCategory(null)"
                color="primary"
                outlined>
                Add
              </v-btn>
            </div>
          </div>
        </div>
        <v-divider color="primary"></v-divider>
        <div
          v-if="walkObservations.length"
          class="px-2 pt-2"
          style="overflow-y: scroll; height: calc(100vh - 64px - 84px)">
          <observation-card
            v-for="(observation, index) in walkObservations"
            @delete="deleteObservation(observation)"
            @edit="editObservation(observation.id)"
            :key="`${index}-observation`"
            :observation="observation"
            :observation-number="index + 1"
            :readonly="!(isUserLeader || observation.reportedByUserId === user.id)"
            :settings="observationSettings"
            class="mb-2">
          </observation-card>
        </div>
        <div
          v-else
          class="text-subtitle-1 grey--text text--darken-1"
          style="overflow-y: scroll; height: calc(100vh - 64px - 84px); display: flex; justify-content: center; align-items: center">
          No Observations Yet
        </div>
      </div>
    </v-navigation-drawer>

    <v-fab-transition v-if="!$vuetify.breakpoint.mdAndUp && walkObservations.length">
      <v-btn
        @click="isObservationsDialogVisible = true"
        fixed
        color="grey darken-1"
        dark
        elevation="12"
        bottom
        left
        rounded
        x-large
        style="left: 100px"
      >
        {{ walkObservations.length }} Entr{{
          walkObservations.length === 1 ? "y" : "ies"
        }}
      </v-btn>
    </v-fab-transition>

    <v-fab-transition v-if="!$vuetify.breakpoint.mdAndUp">
      <v-btn
        @click="isSchoolResourcesDialogVisible = true"
        bottom
        color="#C58C99"
        elevation="12"
        fab
        fixed
        left
      >
        <v-icon color="white">mdi-help-box-multiple-outline</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-fab-transition v-if="!$vuetify.breakpoint.mdAndUp">
      <v-btn
        @click="openCreateObservationDialogForCategory(null)"
        bottom
        color="primary"
        elevation="12"
        fab
        fixed
        right
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <!-- DIALOGS -->
    <create-observation-dialog
      v-model="isCreateObservationDialogVisible"
      @save="saveNewObservation"
      :initial-observation-category-id="newObservationCategoryId"
      :school-id="walk.schoolId"
      :walk-id="walk.id" />

    <create-observation-dialog
      v-model="isEditObservationDialogVisible"
      @save="saveEditObservationChanges($event)"
      :initial-observation="editingObservation"
      :school-id="walk.schoolId"
      :walk-id="walk.id"
      is-editing />

    <v-dialog
      v-model="isSettingsDialogVisible"
      max-width="600px">
      <v-card>
        <div style="display: flex; justify-content: space-between; align-items: center">
          <div>
            <v-card-title>
              SchoolDog Walk Settings
            </v-card-title>
          </div>
          <v-btn @click="isSettingsDialogVisible = false" class="mx-1" icon>
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-card class="mb-2">
            <v-card-text>
              <div class="text-subtitle-1 mb-2 d-flex justify-space-between align-center" style="width: 100%">
                <div>Access info</div>
                <v-btn v-if="user && (walk.leaderUserId === user.id)" text @click="isManageWalkAccessDialogVisible = true" color="primary">Manage access</v-btn>
              </div>
              <div>
                This SchoolDog Walk is being led by <user-link :userId="walk.leaderUserId" />.
              </div>
              <div v-if="walk.collaborators && walk.collaborators.length" class="mt-2">
                <div>
                  Additionally, there {{ walk.collaborators.length === 1 ? 'is' : 'are'}} {{ walk.collaborators.length }} collaborator{{ walk.collaborators.length === 1 ? '' : 's' }}:
                </div>
                <ul>
                  <li v-for="collaborator in walk.collaborators" :key="collaborator">
                    <user-link :userId="collaborator" />
                  </li>
                </ul>
              </div>
              <manage-walk-access-dialog
                v-model="isManageWalkAccessDialogVisible"
                :walk="walk" />
            </v-card-text>
          </v-card>
          <v-card class="mb-2 mt-1">
            <v-card-text>
              <div class="text-subtitle-1 mb-2 d-flex justify-space-between align-center" style="width: 100%">
                <div>School info</div>
                <v-btn text @click="isSchoolResourcesDialogVisible = true" color="#C58C99">View resources</v-btn>
              </div>
              <div class="text-body-2 mb-1">
                <div class="font-weight-thin text-caption">
                  School Name
                </div>
                <div class="font-weight-medium">
                  {{ school.name }}
                </div>
              </div>
            </v-card-text>
          </v-card>
          <div class="d-flex justify-center">
            <v-btn
              @click="isDeleteWalkDialogVisible = true"
              color="error"
              text>
              Permanently delete SchoolDog Walk
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="isSettingsDialogVisible = false"
            color="grey darken-1"
            text>
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isObservationsDialogVisible"
      v-if="isObservationsDialogVisible"
    >
      <v-card>
        <div>
          <div style="display: flex; justify-content: space-between; align-items: center">
            <div>
              <v-card-title>
                Observation entries
              </v-card-title>
              <v-card-subtitle>
                {{ numberOfConcerns }} concern{{numberOfConcerns === 1 ? '' : 's'}}, {{ numberOfPraises}} praise{{numberOfPraises === 1 ? '' : 's'}}, {{ numberOfOther }} other
              </v-card-subtitle>
            </div>
            <v-btn @click="isObservationsDialogVisible = false" class="mx-1" icon>
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <v-divider></v-divider>
          <div
            v-if="walkObservations.length"
            class="px-2 py-2">
            <observation-card
              v-for="(observation, index) in walkObservations"
              @delete="deleteObservation(observation)"
              @edit="editObservation(observation.id)"
              :key="`${index}-observation`"
              :observation="observation"
              :observation-number="index + 1"
              :readonly="!(isUserLeader || observation.reportedByUserId === user.id)"
              :settings="observationSettings"
              class="mb-2">
            </observation-card>
          </div>
          <div
            class="text-subtitle-1 grey--text text--darken-1 pa-2"
            v-else>
            No Entries Yet
          </div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="isObservationsDialogVisible = false"
            color="grey darken-1"
            text>
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isSentForReviewDialogVisible" max-width="528px">
      <v-card v-if="isSentForReviewDialogVisible">
        <v-card-title>
          Revisions Requested
        </v-card-title>
        <v-card-text>
          <div>
            This SchoolDog Walk was reviewed by <span v-if="reviewedBy">{{reviewedBy.firstName}} {{reviewedBy.lastName}}</span><span v-else>Unknown</span>, and revisions were requested for your consideration. Please review the comments below, determine whether to make revisions to the SchoolDog Walk, and then complete and finalize the report.
          </div>
          <div class="mt-2">
            <b>Review Comments</b>
            <div class="text-body-1">
              "{{ walk.submissionHistory[walk.submissionHistory.length - 1].comments }}"
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="isSentForReviewDialogVisible = false" color="primary">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isReviewingSubmissionDialogVisible" max-width="528px">
      <v-card>
        <v-progress-linear
          v-if="isSubmitting"
          indeterminate
          color="primary" />
        <v-form v-model="isSubmissionReviewFormValid" ref="submissionReviewForm">
          <v-card-title>
            Review and Complete
          </v-card-title>
          <v-card-text>
            <div class="d-flex">
              <div>
                You are about to complete a SchoolDog Walk containing {{ numberOfConcerns }} concern{{numberOfConcerns === 1 ? '' : 's'}}, {{ numberOfPraises}} praise{{numberOfPraises === 1 ? '' : 's'}}, and {{ numberOfOther }} other entries. <span v-if="!walkObservations.length">It is recommended to record at least one entry before completing.</span><span v-else>If you have more entries to record, please do so before completing.</span>
              </div>
            </div>
            <v-checkbox
              v-if="!walk.hasSentNeighborhoodWatch && user.can('send neighborhood watch')"
              v-model="walk.shouldSendNeighborhoodWatch"
              @change="saveWalk()">
              <template v-slot:label>
                <div>
                  Send <glossary-term text='"Neighborhood Watch"' /> email after completion
                </div>
              </template>
            </v-checkbox>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="isReviewingSubmissionDialogVisible = false"
              color="grey darken-1"
              text>
              Cancel
            </v-btn>
            <v-btn
              @click="doSubmissionAction()"
              :disabled="isSubmitting"
              :loading="isSubmitting"
              color="primary">
              Complete SchoolDog Walk
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-if="school" v-model="isSchoolResourcesDialogVisible" max-width="640px">
      <v-card>
        <div style="display: flex; justify-content: space-between; align-items: center">
          <v-card-title>
            Resources
          </v-card-title>
          <v-btn @click="isSchoolResourcesDialogVisible = false" class="mx-1" icon>
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <div class="text-h5 mb-1">
            Resources for {{school.name}}
          </div>
          <div>
            <school-form :school="school" class="mb-2"/>
            <school-links-form :schoolInfo="school" :schoolId="walk.schoolId" class="mb-2" />
            <school-files  :schoolId="walk.schoolId" class="mb-2"/>
          </div>

          <div class="text-h5 my-1">
            Resource Links
          </div>
          <resource-link-cards></resource-link-cards>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isDeleteWalkDialogVisible" persistent max-width="528px">
      <v-card>
        <v-card-title>
          Are you sure?
        </v-card-title>
        <v-card-subtitle>
          Are you sure you want to delete this SchoolDog Walk and all observation entries associated with it? This cannot be undone.
        </v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!isSubmitting"
            @click="isDeleteWalkDialogVisible = false"
            color="grey darken-1"
            text
            >No, Do Not Delete
            </v-btn>
            <v-btn
            @click="deleteWalk()"
            :disabled="isSubmitting"
            :loading="isSubmitting"
            color="primary"
            >Yes, Delete
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import uuidv4
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import Walk from '@/models/Walk'
import SchoolLocations from '@/models/SchoolLocations'
import ObservationCard from '../../components/walks/ObservationCard.vue';
import WalkHistoryCard from '../../components/walks/WalkHistoryCard.vue';
import ResourceLinkCards from '../../components/resources/ResourceLinkCards.vue';
import SchoolForm from '../school/SchoolForm.vue';
import SchoolLinksForm from '../school/SchoolLinksForm.vue';
import SchoolFiles from '../school/SchoolFiles.vue';
import WalkHeader from '../../components/walks/WalkHeader.vue';
import CreateObservationDialog from '../../components/walks/CreateObservationDialog.vue';
import UserLink from '../../components/common/UserLink.vue';
import ManageWalkAccessDialog from '../../components/walks/ManageWalkAccessDialog.vue';
import WalkProgressBar from '../../components/walks/WalkProgressBar.vue';
import GlossaryTerm from '../../components/common/GlossaryTerm.vue';

export default {
  components: {
    ObservationCard,
    WalkHistoryCard,
    SchoolFiles,
    SchoolForm,
    SchoolLinksForm,
    WalkHeader,
    ResourceLinkCards,
    ManageWalkAccessDialog,
    CreateObservationDialog,
    UserLink,
    WalkProgressBar,
    GlossaryTerm,
  },
  name: 'EditWalk',
  data() {
    return {
      currentTab: 0, 
      editingObservation: null,
      editingObservationId: null,
      isCreateObservationDialogVisible: false,
      isDeleteWalkDialogVisible: false,
      isEditObservationDialogVisible: false,
      isEditObservationFormValid: false,
      isObservationsDialogVisible: false,
      isObservationsDrawerVisible: true,
      isLoadingWalk: false,
      isSettingsDialogVisible: false,
      isManageWalkAccessDialogVisible: false,
      isReviewingSubmissionDialogVisible: false,
      isSchoolResourcesDialogVisible: false,
      isSentForReviewDialogVisible: false,
      isSubmissionReviewFormValid: false,
      isSubmittingObservation: false,
      isSubmitting: false,
      isUsingTileView: true,
      newObservationCategoryId: null,
      objectRule: value => (Boolean(value) && typeof value === 'object' && Boolean(value.value)) || 'Please choose from provided options',
      requiredRule: value => Boolean(value) || 'This field is required',
      schoolLocations: null,
      submissionReviewSendToSafetyTeam: true,
      submissionReviewerId: null,
      walk: null,
    };
  },
  computed: {
    ...mapGetters('app', [
      'schools',
      'getEligibleReviewersForWalk',
      'getSchoolById',
    ]),
    ...mapState('app', [
      'isOffline',
      'observationSettings',
      'organizationSettings',
      'user',
      'users',
      'walksById',
    ]),
    isUserLeader () {
      return this.user && this.user.id === this.walk.leaderUserId
    },
    numberOfConcerns () {
      return this.walkObservations.filter(observation => 'concern' === observation.observationType).length
    },
    numberOfOther () {
      return this.walkObservations.filter(observation => 'other' === observation.observationType).length
    },
    numberOfPraises () {
      return this.walkObservations.filter(observation => 'praise' === observation.observationType).length
    },
    observationCategoryById() {
      const map = {};
      this.observationCategories.forEach((observationCategory) => {
        map[observationCategory.id] = observationCategory;
      });
      return map;
    },
    observationCategories() {
      if (this.observationSettings) {
        return this.observationSettings.categories;
      }
      return [];
    },
    observationOptionsByCategoryId () {
      const map = {}
      this.observationCategories.forEach((category) => {
        map[category.id] = this.observationItemsByCategory[category.id].map((observationItem) => ({ text: observationItem.label, value: observationItem.id })).sort((a, b) => {
          if (a.text === 'Other' && b.text === 'Other') return 0;
          if (a.text === 'Other') return 1;
          if (b.text === 'Other') return -1;
          return a.text.localeCompare(b.text);
        })
      })
      return map
    },
    observationOptionsWithCategories () {
      const options = []
      this.observationItems.forEach((observationItem) => {
        observationItem.categories.forEach((categoryId) => {
          options.push({
            text: `${observationItem.label} (${this.observationCategoryById[categoryId].label})`,
            value: `${categoryId}__${observationItem.id}`,
          })
        })
      })
      return options.sort((a, b) => {
        if (a.text === 'Other' && b.text === 'Other') return 0;
        if (a.text === 'Other') return 1;
        if (b.text === 'Other') return -1;
        return a.text.localeCompare(b.text);
      });
    },
    observationItems() {
      if (this.observationSettings) {
        return this.observationSettings.items;
      }
      return [];
    },
    observationItemById() {
      const map = {};
      this.observationItems.forEach((observationItem) => {
        map[observationItem.id] = observationItem;
      });
      return map;
    },
    observationItemsByCategory() {
      const map = {};
      if (!this.observationSettings) {
        return map
      }
      this.observationSettings.items.forEach((observationItem) => {
        observationItem.categories.forEach((categoryId) => {
          if (!map[categoryId]) {
            map[categoryId] = []
          }
          map[categoryId].push(observationItem)
        })
      })
      return map;
    },
    reviewedBy() {
      const matchingUsers = this.users.filter((user) => user.id == this.walk.awaitingReviewBy)
      if (matchingUsers.length > 0) {
        return matchingUsers[0];
      }
      return null;
    },
    isLoading() {
      return (
        this.isLoadingWalk
      );
    },
    school () {
      return this.getSchoolById(this.walk.schoolId) || {}
    },
    walkObservations () {
      const observations = Object.values(this.walk.observationsById || {}).filter(observation => !observation.isDeleted)
      observations.sort((a, b) => {
        return new Date(a.timestamp) - new Date(b.timestamp)
      })
      return observations
    },
  },
  methods: {
    ...mapActions('app', [
      'syncWalks',
      'showError',
      'showSuccess',
    ]),
    ...mapMutations('app', [
      'removeWalk',
    ]),
    async completeWalk() {
      this.isSubmitting = true;
      try {
        await this.runFunction('completeWalk', {
          walkId: this.walk.id,
        })
      } finally {
        this.isSubmitting = false
      }
    },
    async deleteObservation(observation) {
      observation.isDeleted = true
      this.walk.save()
    },
    async doSubmissionAction() {
      await this.completeWalk();
    },
    editObservation(observationId) {
      this.editingObservationId = observationId
      this.editingObservation = JSON.parse(JSON.stringify(this.walk.observationsById[observationId]))
      this.isEditObservationDialogVisible = true
    },
    getImagePathForCategory(category) {
      return category.image;
    },
    async deleteWalk() {
      try {
        this.isSubmitting = true;
        await this.runFunction('archiveWalk', {
          walkId: this.walk.id,
        })
        this.showSuccess('Successfully deleted SchoolDog Walk');
        this.removeWalk(this.walk)
        this.$router.replace('/walks');
      } catch {
        this.showError('Failed to delete SchoolDog Walk');
      } finally {
        this.isSubmitting = false;
      }
    },
    async loadWalk() {
      this.isLoadingWalk = true;

      await this.syncWalks()
      try {
        this.walk = await Walk.getById(this.$route.params.walkId)
        if (!this.walk) {
          throw new Error('Walk does not exist');
        }
      } catch (e) {
        console.error(this.$route.params.walkId, e)
        this.$router.replace('/walks');
        return
      }

      if (!this.walk || 'inprogress' !== this.walk.status) {
        this.$router.replace(`/walks/walk/${this.$route.params.walkId}/${this.walk.status}`);
      }

      if (this.walk.submissionHistory && this.walk.submissionHistory) {
        const mostRecentAction = this.walk.submissionHistory[this.walk.submissionHistory.length - 1];
        if (mostRecentAction.fromStatus === 'review' && this.walk.awaitingReviewBy) {
          this.isSentForReviewDialogVisible = true
        }
      }

      this.isLoadingWalk = false;

      this.schoolLocations = await SchoolLocations.getById(this.walk.schoolId)
    },
    openCreateObservationDialogForCategory(observationCategoryId) {
      this.newObservationCategoryId = observationCategoryId;
      this.isCreateObservationDialogVisible = true;
    },
    async saveEditObservationChanges(observation) {
      Object.keys(observation).forEach((key) => {
        this.walk.observationsById[this.editingObservationId][key] = observation[key]
      })
      this.saveWalk('saveedit');
    },
    async saveNewObservation(newObservation) {
      const observation = {
        ...newObservation,
        timestamp: new Date(),
        reportedByUserId: this.user.id,
        id: uuidv4(),
      };
      if (!this.walk.observationsById) {
        this.walk.observationsById = {};
      }
      this.walk.observationsById[observation.id] = observation;
      this.walk.save();

      // Don't bother caching common locations while offline, inefficient use of resources
      if (!this.isOffline && observation.location) {
        if (!this.schoolLocations.usageMap[observation.location]) {
          this.$set(this.schoolLocations.usageMap, observation.location, 0);
        }
        this.$set(this.schoolLocations.usageMap, observation.location, this.schoolLocations.usageMap[observation.location] + 1);
        this.schoolLocations.save();
      }
    },
    async saveWalk() {
      this.walk.save()
    },
    setObservationItemId (observation, observationItem) {
      const observationItemId = observationItem.value
      if (observationItemId && observationItemId.includes('__')) {
        const parts = observationItemId.split('__');
        observation.observationCategoryId = parts[0]
        observation.observationItemId = parts[1]
        this.$forceUpdate()
      } else {
        observation.observationItemId = observationItemId
        this.$forceUpdate();
      }
    },
    scrollToSubmit() {
      this.$refs.submitBtn.$el.scrollIntoView({ behavior: 'smooth' });
    },
  },
  async mounted() {
    await this.loadWalk();
    if (this.walk) {
      this.walk.startListening()
    }

    if (localStorage.getItem(`walk-${this.$route.params.walkId}-tab`)) {
      this.currentTab = parseInt(localStorage.getItem(`walk-${this.$route.params.walkId}-tab`))
      if (this.currentTab < 0 || this.currentTab > 2) {
        this.currentTab = 0;
      }
    } else {
      localStorage.setItem(`walk-${this.$route.params.walkId}-tab`, this.currentTab)
    }
  },
  destroyed () {
    if (this.walk) {
      this.walk.stopListening()
    }
  },
  watch: {
    currentTab () {
      if (0 === this.currentTab && this.walk.currentStage !== 'observations') {
        this.walk.currentStage = 'observations';
      } else if (1 === this.currentTab && this.walk.currentStage !== 'review') {
        this.walk.currentStage = 'review';
      } else if (this.currentTab < 0 || this.currentTab > 2) {
        this.currentTab = 0;
        return;
      }
      localStorage.setItem(`walk-${this.$route.params.walkId}-tab`, this.currentTab)
    },
    'walk.status' () {
      if (this.walk && 'complete' === this.walk.status) {
        this.$router.replace(`/walks/walk/${this.walk.id}/complete`);
        this.showSuccess('SchoolDog Walk Completed');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-navigation-drawer__content {
  padding: 0;
}

</style>

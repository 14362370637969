export default {
  // main navigation - side menu
  menu: [
    {
      text: '',
      key: '',
      isDashboard: true,
      items: [
        { icon: 'mdi-view-dashboard-outline', key: 'menu.dashboard', text: 'Dashboard', link: '/dashboard', permissions: { canAtAnySchool: 'create walks|manage walks|manage other walks|collaborate on walks|view observation leaderboard' } },
        // { icon: 'mdi-chat-outline', key: 'menu.messaging', text: 'Messaging', link: '/messaging' },
        // { icon: 'mdi-security', key: 'menu.administrator', text: 'Administrator', link: '/administrator' },
        // { icon: 'mdi-file-chart-outline', key: 'menu.reports', text: 'Reports', link: '/reports' },
      ],
    },
    {
      text: 'SchoolDog Walks',
      permissions: {
        canAtAnySchool: 'create walks|manage walks|manage other walks|collaborate on walks|view data insights',
        or: true,
      },
      items: [
        { icon: 'mdi-walk', key: 'menu.schoolDogWalks', text: 'SchoolDog Walks', link: '/walks', worksOffline: true, permissions: { canAtAnySchool: 'create walks|manage walks|manage other walks|collaborate on walks'} },
        { icon: 'mdi-sync', key: 'menu.cycles', text: 'Cycles', link: '/cycles', permissions: { can: 'view cycles|manage cycles' } },
        { text: 'Data Insights', link: '/insights', icon: 'mdi-chart-line', permissions: { canAtAnySchool: 'view data insights' }  },
      ],
    },
    {
      text: 'Application',
      permissions: {
        can: 'manage users|view district|download administrative reports',
      },
      items: [
        {
          icon: 'mdi-file-chart',
          key: 'Administrative Reports', text: 'Administrative Reports', link: '/reports', permissions: { can: 'download administrative reports' },
        },
        { icon: 'mdi-account-group', key: 'menu.admin.manageUsers', text: 'Manage Users', link: '/admin/manageUsers', permissions: { can: 'manage users' } },
      ],
    },
    {
      text: 'Resources',
      items: [
        { icon: 'mdi-link-box', key: 'menu.resourceLinks', text: 'Resource Links', link: '/resources/links', worksOffline: true },
        // help page
        { icon: 'mdi-help-circle-outline', text: 'Get Support', link: '/faq', worksOffline: true },
      ],
    },
    {
      text: 'SchoolDog Staff Settings',
      requireSchoolDogStaff: true,
      items: [
        { icon: 'mdi-security', key: 'menu.admin.schooldog', text: 'Dev Settings', link: '/admin/schooldog', requireSchoolDogStaff: true },
        { icon: 'mdi-tune-vertical-variant', text: 'Observation Settings', link: '/walkSettings', requireSchoolDogStaff: true },
      ],
    },
    // {
    //   text: 'Get Help',
    //   key: 'menu.getHelp',
    //   items: [
    //     { icon: 'mdi-face-agent', key: 'menu.contactSupport', text: 'Contact Support', link: '/support' },
    //     { icon: 'mdi-help-circle-outline', key: 'menu.frequentlyAskedQuestions', text: 'Frequently Asked Questions', link: '/faq' },
    //   ],
    // },
    {
      text: 'Legal',
      key: 'menu.legal',
      items: [
        { icon: 'mdi-file-document-outline', key: 'menu.legalSection.termsOfUse', text: 'Terms and Conditions', link: '/terms' },
        { icon: 'mdi-file-eye-outline', key: 'menu.legalSection.privacyPolicy', text: 'Privacy Policy', link: '/privacy' },
      ],
    },
  ],

  // footer links
  footer: [],
}
